import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";

export const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/welcome",
    name: "首页",
    meta: { showInlayout: false },
    component: () => import("@/views/Welcome.vue"),
    children: [],
  },
  {
    path: "/welcome",
    name: "欢迎",
    meta: { showInlayout: false },
    component: () => import("@/views/Welcome.vue"),
    children: [],
  },
];

export const router = createRouter({
  history: createWebHashHistory(
    // @ts-ignore
    window.__POWERED_BY_QIANKUN__ ? '/qiankun' : '/'
  ),
  routes,
});
