import { createApp } from "vue";
import App from "./App.vue";
import { router } from "./router";
import { createPinia } from "pinia";
import { titleFc } from "../config/config-function";
import "../config/request.config";
import "vant/lib/index.css";
import "./public-path";
import { useStore } from "./store";

// 路由 beforeEach 钩子
router.beforeEach((to, from, next) => {
  titleFc(to.name);
  next();
});

let instance = null;

function render(props = {}) {
  // @ts-ignore
  const { container } = props;
  const renderContainer = container
    ? container.querySelector("#container")
    : "#container";
  instance = createApp(App);
  instance.use(createPinia());
  instance.use(router);
  instance.mount(renderContainer);
  useStore().$patch({ qiankun: props });
}

// 独立运行时渲染
// @ts-ignore
if (!window.__POWERED_BY_QIANKUN__) {
  render();
}

// 暴露生命周期方法
export async function bootstrap() {
  console.log("[qiankun] bootstraped");
}

export async function mount(props) {
  console.log("[qiankun] props from main framework", props);
  render(props);
}

export async function unmount() {
  instance.$destroy();
  instance.$el.innerHTML = "";
  instance = null;
}
