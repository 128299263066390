<template>
    <router-view />
</template>

<script lang="ts" setup>
import { initFc } from "../config/config-function";

initFc();
</script>

<style>
#app {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}
</style>
