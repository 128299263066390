const { config } = require("./config");
import type { RouteRecordName } from "vue-router";

export const initFc = () => {
  titleFc();
};

export const titleFc = (titleData?: string | RouteRecordName | null) => {
  const { title }: { title: string } = config;
  document.title = titleData?.toString() || title;
};

export const autoBreadcrumbFc = () => {
  const { autoBreadcrumb = false }: { autoBreadcrumb?: boolean } = config;
  return autoBreadcrumb;
};

