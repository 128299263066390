import request from "umi-request";
// import { message } from "ant-design-vue";

// request拦截器, 改变url 或 options.
request.interceptors.request.use(
  (url, options) => {
    // 拦截请求配置，进行个性化处理。
    const Authorization = localStorage.getItem("Authorization") || "";
    return {
      url: `/api${url}`,
      options: {
        ...options,
        interceptors: true,
        headers: {
          ...options.headers,
          Authorization: "Bearer " + Authorization,
        },
      },
    };
  },
  { global: true }
);

// response拦截器, 处理response
request.interceptors.response.use((response) => {
  return response;
});

// 对响应做异常处理
request.interceptors.response.use(async (response) => {
  // const codeMaps = {
  //   400: "发出的请求有错误，服务器没有进行新建或修改数据的操作",
  //   401: "用户没有权限",
  //   403: "用户得到授权，但是访问是被禁止的",
  //   404: "发出的请求针对的是不存在的记录，服务器没有进行操作",
  //   406: "请求的格式不可得",
  //   410: "请求的资源被永久删除，且不会再得到的",
  //   422: "当创建一个对象时，发生一个验证错误",
  //   500: "服务器发生错误，请检查服务器",
  //   502: "网关错误",
  //   503: "服务不可用，服务器暂时过载或维护",
  //   504: "网关超时",
  // };
  if (response.status >= 300) {
    // message.error(
    //   (await response.json())?.data ||
    //     codeMaps[response.status as keyof typeof codeMaps]
    // );
  }
  return response;
});
